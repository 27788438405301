/**
 * Pluralizes a word based on the given value.
 *
 * @param {number} value - The value used to determine the pluralization.
 * @param {string | string[]} words - The word(s) to be pluralized. If an array is provided, the first element represents the singular form and the second element represents the plural form.
 * @return {string} The pluralized word based on the value. If the value is -1, 0, or 1, the corresponding word will be returned. Otherwise, the plural form of the word will be returned.
 */
export const pluralize = (value: number, words: string | string[]) => {
  if (!Array.isArray(words)) {
    return [-1, 0, 1].includes(value) ? words : `${words}s`
  }
  const [singular, plural] = words
  return [-1, 0, 1].includes(value) ? singular : plural
}
