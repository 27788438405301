import type { ErrorOption } from 'react-hook-form'

import { pluralize } from '~/utils/text.util'

const pluralizeCharacters = (value: number) =>
  pluralize(value, ['caracter', 'caracteres'])

export const MIDDLEWARE_ERROR = (name: string, message: string) => {
  const error_message = `Middleware error [${name}]: ${message}`
  return new Error(error_message)
}

export const SIMULATIION_REQUIRED = 'A simulation is required for this route.'
export const LEAD_REQUIRED = 'A lead is required for this route.'
export const PROPOSAL_REQUIRED = 'A proposal is required for this route.'

export const CONTRACT_NOT_FOUND = 'Contract not found.'
export const CONTRACT_MISSING_SIGNATURE = 'Contract does not have a signature.'
export const PROPOSAL_MISSING_ID = 'Missing proposal ID.'

export const INVALID_BODY = 'INVALID_BODY'
export const FIELD_VALIDATION = 'FIELD_VALIDATION'
export const BACKEND_ERROR = 'BACKEND_ERROR'
export const CEP_NOT_FOUND = 'CEP_NOT_FOUND'

export const COMPANY_NAME = 'Digite o nome da sua empresa'
export const FULL_NAME = 'Digite seu nome completo'
export const INVALID_CNPJ = 'CNPJ inválido'
export const INVALID_CPF = 'CPF inválido'
export const INVALID_CPF_OR_CNPJ = 'CPF/CNPJ Inválido'
export const INVALID_DATE = 'Data inválida'
export const INVALID_EMAIL = 'Digite um e-mail válido'
export const INVALID_INSTALLATION_NUMBER = 'Número de instalação inválido.'
export const INVALID_CLIENT_NUMBER = 'Número do cliente inválido.'
export const INVALID_MEMBER = 'invalid member id'
export const INVALID_EXPERIMENT = 'invalid experiment'
export const INVALID_PHONE = 'Digite seu telefone'
export const INVALID_REGION =
  'No momento ainda não temos uma usina específica na sua região, esperamos chegar em breve!'
export const INVALID_REFERRAL_CODE = 'Código de convidado inválido.'
export const INVALID_POSTAL_CODE = 'CEP inválido.'
export const BILL_DISCO_IS_NOT_ELIGIBLE =
  'Distribuidora não é uma valor elegível.'
export const BILL_DISCO_IS_NOT_RECONIZED =
  'Distribuidora não é uma key conhecida.'
export const LEGAL_REPRESENTATIVE =
  'É necessário declarar-se representante legal da empresa'
export const PRIVACY_POLICY =
  'É necessário aceitar a Política de Privacidade para continuar'
export const REQUIRED = 'Obrigatório'
export const INVALID_STATE = 'Selecione um estado válido'
export const INVALID_CITY = 'Selecione uma cidade válida'
export const ONLY_NUMBERS = 'Apenas números'
export const INVALID_ADDRESS_NUMBER =
  'Apenas números. Não possui número? Use S/N.'
export const LENGTH = (length: number) =>
  `Deve ter exatamente ${length} ${pluralizeCharacters(length)}.`
export const MIN_LENGTH = (length: number) =>
  `Deve ter ao menos ${length} ${pluralizeCharacters(length)}.`
export const MAX_LENGTH = (length: number) =>
  `Deve ter no maximo ${length} ${pluralizeCharacters(length)}.`
export const INVALID_ENUM_VALUE = (list?: string[]) =>
  !list
    ? `Selecione um elemento da lista`
    : `Selecione uma das seguintes opções: ${list.join(', ')}`

export const INVALID_DOCUMENT_TYPE = 'Selecione um tipo de documento válido'
export const INVALID_BILL_OWNERSHIP_TYPE =
  'Selecione um tipo de titularidade válido'

export const FAILED_FORM_VALIDATION =
  'One or more form fields failed validation.'
export const MISSING_PROPOSAL_ID = 'Missing proposal id.'
export const FAILED_BODY_VALIDATION = 'Please, provide the necessary body data.'
export const FAILED_URL_PARAMS_VALIDATION =
  'Please, provide the necessary URL parameters.'

export const ZOD_PARSE_FORMDATA_FAILED = 'Zod failed parsing the formData'
export const FILE_PUT_UPLOAD_ERROR = 'Error while uploading files.'
export const DOCUSIGN_SCRIPT_FAILED_TO_LOAD =
  'Docusign script did not load properly.'
export const DOCUSIGN_SCRIPT_TOKEN_EXPIRED = 'Token has expired'

export const DOCUMENT_DETECTOR_SCRIPT_FAILED_TO_LOAD =
  "The Document Detector script didn't load properly."
export const DOCUMENT_DETECTOR_SCRIPT_FAILED_TO_INIT =
  "The Document Detector script didn't initialize properly."
export const DOCUMENT_DETECTOR_SDK_CONTAINER_NOT_FOUND =
  "The Document Detector didn't find #sdk-displayer element on the DOM."
export const DOCUMENT_DETECTOR_CAPTURE_FAILED =
  'The Document Detector failed to capture the document.'

export const POLLING_ERROR = (name: string, message: string) =>
  `[${name} polling]: ${message}`

export const CEP_INPUT_SERVER_ERROR: ErrorOption = {
  type: 'custom',
  message: INVALID_POSTAL_CODE,
}

export const CEMIG_CLIENT_NUMBER_ERROR =
  'Número do cliente inválido. São 10 dígitos, geralmente começado em 7.'
export const CEMIG_INSTALLATION_NUMBER_ERROR =
  'Número de instalação inválido. São 10 dígitos, geralmente começado em 3.'

export enum ERROR_STATUS_CODES {
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  SERVER_ERROR = 500,
}
